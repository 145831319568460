import {Injectable} from '@angular/core';
import {WindowService, ApiService, SessionStateModel, merge, SessionState} from '@myvf/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class AnchorService {

  constructor(private api: ApiService, private win: WindowService, private store: Store) {
  }

  /**
   * Sanitize pbo param into android OS url scheme
   * @param anchor client's url scheme
   * @param pbo param to be sanitized
   */
  androidAnchorSanitized(anchor: string, pbo = ''): string {
    if (/\/native/.test(anchor)) {
      return anchor;
    }
    const domain = anchor.split('?')[0];
    const anchorParams = this.win.decodeQueryParams(anchor);
    const pageUrl = this.win.decode(anchorParams['pageURL']);
    const pageUrlParams = this.win.encodeQueryParams({...this.win.decodeQueryParams(pageUrl), ...pbo && {pbo}});
    const encodedPageUrl = `${this.win.encode(pageUrl.split('?')[0])}?${this.win.encode(this.win.encode(pageUrlParams))}`;
    const sanitizedParams = {...anchorParams, pageURL: encodedPageUrl};
    return `${domain}?${this.win.encodeQueryParams(sanitizedParams)}`;
  }

  /**
   * Get the OneCLick anchor
   * @param sessionData session state data
   * @return Observable<any>
   */
  getRechargeOneClickAnchor(sessionData: SessionStateModel, icmp?: string): Observable<any> {
    const {value, type_sim, status, items, user:{isNext}} = sessionData;
    let anchorId: string;
    let args = null;

    if (((!isNext && type_sim === 'ricaricabile') || (isNext && items.find(obj => obj.value === value)?.planType === 'prepaid')) && status !== 'anonymous') {
      args = {
        msisdn: value,
        ...icmp && {icmp: icmp}
      };
      if(isNext){
        anchorId = 'NA_RICARICA_WITH_TOKEN_NO_OV';
      } else {
        anchorId = 'NA_RICARICA_WITH_TOKEN_NO_OV_LEGACY';
      }
    } else {
      anchorId = 'NA_RICARICA_ANONYMOUS_NO_OV';
    }

    return this.lookUp(anchorId, args);
  }

  getExternalAnchor(anchorId: string, args: any): Observable<any> {
    const {type, value} = this.store.selectSnapshot(SessionState);

    const isItemPresent: boolean = (args?.msisdn || args?.landline);

    args = {
      ...args,
      ...(!isItemPresent && type === 'sim') && {msisdn: value},
      ...(!isItemPresent && type === 'landline') && {landline: value},
    };

    return this.lookUp(anchorId, args);
  }

  /**
   * api service for check offers consistency (Jakala)
   * available only for type sim
   */
  checkOffersConsistencyExternalAnchorJakala(args: any): Observable<any> {
    const {type, value} = this.store.selectSnapshot(SessionState);

    return this.api
      .sim
      .checkConsistency(value, args)
      .pipe(
        map(data => {
          const {isEligible, offerIn} = data.result;

          return {
            ...args,
            ...{hasSpecial: (isEligible) ? 'Y' : 'N'},
            ...{specialId: (isEligible && offerIn && offerIn[0]) ? offerIn[0] : ''},
            ...type === 'sim' && {msisdn: value},
            ...type === 'landline' && {landline: value}
          };
        })
      );
  }

  /**
   * api service for anchors resolve
   * @param anchorId, id of anchor
   * @param args, optional if anchor must have args
   * @return Observable<any>
   */
  lookUp(anchorId: string, args?: any): Observable<any> {
    return this.api
      .anchors
      .getLookup(anchorId, args)
      .pipe(
        map(data => {
          let {actionUrl} = data.result;

          if (this.win.isAndroid() && typeof actionUrl !== 'undefined') {
            const {auth: {pbo}} = this.store.selectSnapshot(SessionState);

            actionUrl = this.androidAnchorSanitized(actionUrl, pbo);
            data = merge([data, {result: {actionUrl}}]);
          }

          return data;
        })
      );
  }
}
